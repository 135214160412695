<template>
  <div>
    <AppMenu />
    <BaseBreadcrumb
      :title="$t(title)"
      :help="help"
      :breadcrumbs="breadcrumbs"
      :tutorial="tutorial"
      module="cash-register-turn"
    >
    </BaseBreadcrumb>

    <v-card class="mt-3 mx-3">
      <div class="pl-2">
        <div class="d-flex justify-star pl-2 mt-2 mb-2">
          <ng-filters-chips
            :form="form"
            v-show="form"
            @change="search(form, 1)"
            :labels="{ cash_register: 'cash_register' }"
          ></ng-filters-chips>
        </div>
        <ValidationObserver
          v-slot="{}"
          v-if="showFilters"
          ref="form"
          class="panel-filter"
        >
          <div class="mt-2" style="height: 100%">
            <div class="ml-4 mb-4">
              <v-btn
                small
                class="btn-margin0 btn-cash-register-turn-show-filters"
                color="secondary"
                @click="showFilters = false"
              >
                <v-icon left>mdi-close</v-icon>{{ $t("close") }}
              </v-btn>
              <v-btn
                small
                class="btn-margin0 ml-1 btn-cash-register-turn-search"
                color="primary"
                @click="searchAndClose(form, 1)"
              >
                <v-icon left>mdi-filter</v-icon> {{ $t("search") }}
              </v-btn>
            </div>
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12" class="ml-2 mb-2">{{ $t("filters") }}</v-col>
                <v-col
                  class="cols-filters input-filter-cash_register"
                  cols="12"
                >
                  <ng-chips
                    v-model="form.cash_register"
                    label="cash_register"
                    url="api/v1/list/cash_register"
                  ></ng-chips>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </ValidationObserver>
      </div>

      <div class="px-2">
        <v-data-table
          mobile-breakpoint="300"
          :options.sync="options"
          :disable-filtering="true"
          :disable-sort="true"
          v-on:update:page="changePage"
          v-on:update:sort-by="changeOrder"
          v-on:update:sort-desc="changeOrder"
          v-on:update:items-per-page="changeItemsPerPage"
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :hide-default-footer="hidePaginator()"
          :loading="loading"
          class="listing-app"
          item-key="id"
          :show-expand="false"
          :expanded.sync="expanded"
          :footer-props="{
            'items-per-page-options': options.itemsPerPageOptions,
            'items-per-page-text': $t('records_per_page'),
          }"
        >
          <template v-slot:item._actions="{ item }">
            <div class="d-flex justify-center">
              <v-btn
                small
                tile
                plain
                class="mr-1"
                :title="$t('print_turn')"
                @click="executePrintTurn(item)"
              >
                <v-icon>mdi-printer</v-icon>
                {{ $t("turn") }}
              </v-btn>
              <v-btn
                small
                tile
                plain
                class="mr-1"
                :title="$t('print_report_fiscal')"
                @click="executePrintReportFiscalServer(item)"
              >
                <v-icon>mdi-printer</v-icon>
                {{ $t("report_fiscal") }}
              </v-btn>
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.cash_register
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-cash_register"
            >
              {{ $t("cash_register") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.opening_date
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-opening_date"
            >
              {{ $t("opening_date") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.closing_date
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-closing_date"
            >
              {{ $t("closing_date") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.starting_amount
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-starting_amount"
            >
              {{ $t("starting_amount") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.total_sales
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-total_sales"
            >
              {{ $t("total_sales") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.final_amount
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-final_amount"
            >
              {{ $t("final_amount") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.difference
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-difference"
            >
              {{ $t("difference") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.delivery_amount
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-delivery_amount"
            >
              {{ $t("delivery_amount") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:header.tip_amount
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-tip_amount"
            >
              {{ $t("tip_amount") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <!-- <template v-slot:header.is_sync
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-is_sync"
            >
              {{ $t("is_sync") }}
            </span>
          </template> -->
          <!-- eslint-disable-next-line -->
          <template v-slot:header.opened
            ><span
              class="body-2 font-weight-bold"
              id="header-item-cash-register-turn-opened"
            >
              {{ $t("opened") }}
            </span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.cash_register="{ item }">
            <div
              class="field-cash-register-turn-cash_register"
              :class="'cash-register-turn-cash_register-' + item.id"
            >
              {{ $filters.name(item.cash_register) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.opening_date="{ item }">
            <div
              class="field-cash-register-turn-opening_date"
              :class="'cash-register-turn-opening_date-' + item.id"
            >
              {{ $filters.date(item.opening_date) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.closing_date="{ item }">
            <div
              class="field-cash-register-turn-closing_date"
              :class="'cash-register-turn-closing_date-' + item.id"
            >
              {{ $filters.date(item.closing_date) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.starting_amount="{ item }">
            <div
              class="field-cash-register-turn-starting_amount"
              :class="'cash-register-turn-starting_amount-' + item.id"
            >
              {{ $filters.currency(item.starting_amount) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.total_sales="{ item }">
            <div
              class="field-cash-register-turn-total_sales"
              :class="'cash-register-turn-total_sales-' + item.id"
            >
              {{ $filters.currency(item.total_sales) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.final_amount="{ item }">
            <div
              class="field-cash-register-turn-final_amount"
              :class="'cash-register-turn-final_amount-' + item.id"
            >
              {{ $filters.currency(item.final_amount) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.difference="{ item }">
            <div
              class="field-cash-register-turn-difference"
              :class="'cash-register-turn-difference-' + item.id"
            >
              {{ $filters.currency(item.difference) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.delivery_amount="{ item }">
            <div
              class="field-cash-register-turn-delivery_amount"
              :class="'cash-register-turn-delivery_amount-' + item.id"
            >
              {{ $filters.currency(item.delivery_amount) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.tip_amount="{ item }">
            <div
              class="field-cash-register-turn-tip_amount"
              :class="'cash-register-turn-tip_amount-' + item.id"
            >
              {{ $filters.currency(item.tip_amount) }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <!-- <template v-slot:item.is_sync="{ item }">
            <div
              class="field-cash-register-turn-is_sync"
              :class="'cash-register-turn-is_sync-' + item.id"
            >
              <span class="field-string">
                <v-chip small dark color="primary" v-if="item.is_sync">
                  {{ $t("synchronized") }}
                </v-chip>
                <v-chip small dark color="red" v-else>
                  {{ $t("no_synchronized") }}
                </v-chip>
              </span>
            </div>
          </template> -->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.opened="{ item }">
            <div
              class="field-cash-register-turn-opened"
              :class="'cash-register-turn-opened-' + item.id"
            >
              <span class="field-string">
                <v-chip small dark color="success" v-if="item.opened">
                  {{ $t("opened") }}
                </v-chip>
                <v-chip small dark color="red" v-else>
                  {{ $t("closed") }}
                </v-chip>
              </span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import AppMenu from "../menu/AppMenu.vue";
import NextMixinListPage from "@/components/mixins/NextMixinListPage";
import MixinActions from "@/components/mixins/MixinListActions";
/*
import * as templateDesktop from "./CashRegisterTurnListDesktop.vue?vue&type=template";
import * as templateMobile from "./CashRegisterTurnListMobile.vue?vue&type=template";
let render = templateDesktop.render;
if (window.innerWidth < 960) {
  render = templateMobile.render;
}*/

export default {
  mixins: [NextMixinListPage, MixinActions],
  components: { AppMenu },
  //render: render,
  data() {
    return {
      title: "Turnos",
      form: {},
      url: "api/v1/cash-turn",
      pathBase: "/bill/cash-turn",
      pathCreate: "/bill/cash-turn/create",
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100],
        sortBy: ["opening_date"],
        sortDesc: [true],
      },

      headers: [
        { title: "num", value: "index", sortable: false },
        { title: "cash_register", value: "cash_register", sortable: false },
        { title: "opening_date", value: "opening_date", sortable: true },
        { title: "closing_date", value: "closing_date", sortable: true },
        { title: "starting_amount", value: "starting_amount", sortable: true },
        { title: "total_sales", value: "total_sales", sortable: true },
        { title: "final_amount", value: "final_amount", sortable: true },
        { title: "difference", value: "difference", sortable: true },
        { title: "delivery_amount", value: "delivery_amount", sortable: true },
        { title: "tip_amount", value: "tip_amount", sortable: true },
        { title: "is_sync", value: "is_sync", sortable: true },
        { title: "opened", value: "opened", sortable: true },
        { title: "_actions", value: "_actions", sortable: false },
      ],
    };
  },
  methods: {
    breadcrumbsInit() {
      this.breadcrumbs = [
        {
          text: this.$t("Inicio"),
          disabled: false,
          to: "/pos",
          "exact-path": true,
        },
        {
          text: this.$t("Turnos"),
          disabled: false,
          "exact-path": true,
        },
        {
          text: this.$t("cash-register-turn-list"),
          disabled: true,
          to: "",
          "exact-path": true,
        },
      ];
    },

    search(params, page) {
      //console.log(this.$store.getters.installation, '----------')
      if(!this.$store.getters.installation){
        setTimeout(() => {
          this.search(params, page);
        }, 1500);
        return;
      }
      let cashRegister = { id: this.$store.getters.installation.cash_id };
      const { data, paginate, order } = this.getParamsForQuery(params, page);

      this.querySelections(
        { ...data, cash_register: cashRegister },
        paginate,
        order
      ).then((data) => {
        this.items = data.items;
        this.total = data.total;
        this.moduleActions = data.module_actions;
        this.itemsKey++;
      });
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },

    async executePrintTurn(item) {
      this.$pos.printTurn(item);
    },

    async executePrintReportFiscal(item) {
      this.$pos.printTurnReportFiscal(item);
    },
    async executePrintReportFiscalServer(item) {
      this.$http
        .get("api/v1/cash-turn-tax-report/" + item.id, {}, true, false)
        .then((response) => {
          if (response.success) {
            if (response.data) {
              this.$pos.printTurnReportFiscalServer(response.data);
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
